import React from "react"
import PressAlbum from "../../components/PressAlbum"
import { getAlbum } from "../../data/albums"

const mobius = getAlbum("mobius-ship")

export default () => (
  <PressAlbum
    album={mobius}
    download={true}
    colors={{
      albumBorder: "black",
      background: "white",
      text: "black",
      title: "black",
    }}
  >
    <p>The 6th album, description goes here!</p>
  </PressAlbum>
)
