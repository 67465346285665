import React from "react"
import styled from "styled-components"

import { Files, Track } from "../data/albums"

const TrackList = styled.ol`
  @media (min-width: 600px) {
    width: 600px;
  }
  li {
    margin-bottom: 10px;
  }
`

const DurationWrapper = styled.span`
  font-size: 14px;
`

const TrackName = styled.strong``

const TrackDuration = ({ duration }: { duration: number }) => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60
  return (
    <DurationWrapper>
      ({minutes}:{seconds < 10 ? "0" : ""}
      {seconds})
    </DurationWrapper>
  )
}

const DownloadLinksWrapper = styled.div`
  font-size: 14px;
`
const DownloadLink = ({ file, title }: { file?: string; title: string }) => {
  if (!file) return null
  return <a href={`https://files.thewigout.life/${file}`}>{title}</a>
}

const DownloadLinks = ({ files }: { files?: Files }) => {
  if (!files) return null
  return (
    <DownloadLinksWrapper>
      <DownloadLink file={files.mp3} title="mp3" /> |{" "}
      <DownloadLink file={files.wav} title="wav" />
    </DownloadLinksWrapper>
  )
}

const Tracks = (props: { tracks: Track[] }) => (
  <TrackList>
    {props.tracks.map(t => (
      <li>
        <TrackName>{t.name}</TrackName> <TrackDuration duration={t.length} />
        <DownloadLinks files={t.files} />
      </li>
    ))}
  </TrackList>
)

export default Tracks
