export interface Person {
  name: string
  projects: string[]
  location: string
  link?: string
}

const people = {
  adam: {
    name: "Adam Pearson",
    projects: ["Swift-Tuttle"],
    location: "Olympia WA",
  },
  barton: {
    name: "Barton McGuire",
    projects: ["Mugatu", "Earth To Zena"],
    location: "Boston MA",
  },
  dave: {
    name: "Dave Foley",
    projects: ["Hypatia Lake", "Wumpus Hunter"],
    location: "Seattle WA",
  },
  eric: {
    name: "Eric Peacock",
    location: "Seattle WA",
  },
  tq: { name: "TQ Berg" },
  mark: {
    name: "Mark Kornblum",
    projects: ["Lady Drama"],
    location: "Seattle WA",
  },
  shawn: {
    name: "Shawn Dempsey",
    projects: ["Slowpøke Crew"],
    location: "Seattle WA",
  },
  steve: {
    name: "Steve Shapero",
    projects: ["Computer Paul"],
    location: "San Francisco CA",
  },
  matt: { name: "Matt Sheldon" },
  dan: { name: "Dan Schwartz" },
  adrian: {
    name: "Adrian Woods",
    projects: ["Map of the Woulds", "Neon Brown"],
  },
  andrew: {
    name: "Andrew Woods",
    projects: ["Map of the Woulds", "Neon Brown"],
  },
  josh: { name: "Josh Hou", projects: ["How Short"] },
  thom: { name: "Thomas Geibel" },
  alex: { name: "Alex Sipahiaoglu" },
  elise: { name: "Elise Loeb" },
  genny: { name: "Goldsuit", link: "https://www.artofgoldsuit.com/" },
  jack: { name: "Jack Knight" },
  eddie: { name: "Eddie Bond" },
  stevem: { name: "Steve Monnot" },
  dick: { name: "Dick Valentine" },
  ben: { name: "Ben McAllister" },
  dank: { name: "Daniel Koren" },
  trevor: { name: "Trevor Redfern" },
  johnny: { name: "Johnny Harris" },
}

export type PersonId = keyof typeof people

const defaults = {
  location: "Seattle, WA",
  projects: [],
  name: "",
}
export const getPerson = (id: PersonId): Person => {
  return { ...defaults, ...people[id] }
}
