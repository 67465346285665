import React from "react"
import { Album } from "../data/albums"
import Layout from "./Layout"
import AlbumCover from "./AlbumCover"
import AlbumText from "./AlbumText"
import Tracks from "./Tracks"
import Title from "./Title"
import People from "./People"

interface AlbumColors {
  background: string
  text: string
  title: string
  titleShadow?: string
  albumBorder?: string
}

interface Props {
  album: Album
  colors: AlbumColors
  download: boolean
}

const PressAlbum: React.FC<Props> = ({ album, colors, children }) => (
  <Layout backgroundColor={colors.background} textColor={colors.text}>
    <AlbumCover filename={album.coverImage} borderColor={colors.albumBorder} />
    <Title color={colors.title}>The Wig Out: {album.name}</Title>
    <p>
      Release Date: <strong>{album.releaseDate}</strong>
    </p>
    <AlbumText>{children}</AlbumText>
    {(album.tracks.length > 0 && (
      <>
        <Title color={colors.title}>Tracks</Title>
        <Tracks tracks={album.tracks} />
      </>
    )) ||
      ""}
    <Title color={colors.title}>People</Title>
    <AlbumText>Recorded and mixed {album.recordedDate} by:</AlbumText>
    <People album={album} />
  </Layout>
)

export default PressAlbum
