import React from "react"
import styled from "styled-components"

import { getPerson, PersonId } from "../data/people"
import { Album } from "../data/albums"
export interface Person {
  name: string
}

const PersonList = styled.ul`
  max-width: 600px;
  margin: 15px 0;
  padding: 0;
  @media (min-width: 600px) {
    margin: 0;
    width: 600px;
  }
  li {
    margin: 10px 0 0 0;
    list-style: none;
    span {
      font-weight: bold;
    }
  }
`
const Person = ({ personId }: { personId: PersonId }) => {
  const p = getPerson(personId)
  if (p.link) {
    return <a href={p.link}>{p.name}</a>
  }
  return <span>{p.name}</span>
}

const JoinedPeople = ({ personIds }: { personIds: PersonId[] }) => {
  if (personIds.length === 1) return <Person personId={personIds[0]} />
  const copy = personIds.slice()
  const last = copy.pop()
  const penultimate = copy.pop()
  return (
    <>
      {copy.map(id => (
        <React.Fragment key={id}>
          <Person personId={id}></Person>,{" "}
        </React.Fragment>
      ))}
      <Person personId={penultimate} /> and <Person personId={last} />
    </>
  )
}

const People = ({ album }: { album: Album }) => (
  <PersonList>
    {album.people.map(personId => (
      <li>
        <Person personId={personId} />
      </li>
    ))}
    <li>
      Mastered by <Person personId={album.mastering} />
    </li>
    <li>
      Art by <JoinedPeople personIds={album.art} />
    </li>
  </PersonList>
)

export default People
